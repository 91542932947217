@import 'variable';
@import 'font';
@import 'reset';
@import 'mixins';
@import 'functions';
@import 'lang';
@import 'utils';
/* setup for keep aspect ratio viewport*/
@import 'layout';

@import 'pages/home';

//COMPONENTS
@import 'components/modal';
@import 'components/navbar';
@import 'components/winnerbox';
@import 'components/progressbar';
@import 'components/subtitle';
@import 'components/spin';

/* FIXED FLICKER MODAL */
.react-responsive-modal-overlay, 
.react-responsive-modal-container, 
.react-responsive-modal-modal,
.react-responsive-modal-root 
{
    animation-fill-mode: forwards!important;
    top: 0 !important;
}

html {
	top: 0;
	position: fixed;
	width: 100%;
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #000;
}
