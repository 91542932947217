.btn-active {
  background: var(--img-btn-general-bg) center/100% 100% no-repeat;
  color: var(--color-btn-active);
  text-transform: uppercase;
  padding-top:0.02rem;
  font-family: "GunarBold";
}
.btn-inactive {
  background: var(--img-btn-raw-bg) center/100% 100% no-repeat;
  color: var(--color-btn-inactive);
  text-transform: uppercase;
  padding-top:0.02rem;
  font-family: "GunarBold";
}
.button-section {
  display: flex;
  justify-content: space-between;
}
.disabled {
  pointer-events: none;
}
.grayscale {
  filter: grayscale(1);
}

.title-gradient {
  background: -webkit-linear-gradient(
    var(--color-title-gradient-start),
    var(--color-title-gradient-finish)
  );
  background-clip: text;
  -webkit-text-fill-color: #0000;
  text-transform: uppercase;
}
