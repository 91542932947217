.winner-box {
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 5%;

  .box {
    display: flex;
    flex-direction: column;
    width: 2.7rem;
    height: 2.7rem;
    gap: 0.1rem;


    .box-title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 19%;
      background: var(--img-bg-winner) center/100% 100% no-repeat;
      font-size: 0.22rem;
      margin-bottom: 3%;
      color: var(--color-winner-box);
      text-transform: uppercase;
    }

    .reward-area {
      display: flex;
      justify-content: space-around;
      width: 100%;
      height: 43%;

      .reward-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42%;
        height: 100%;

        >img {
          width: 80%;
          position: relative;
        }
      }
    }
  }

  .btn-claim {
    margin: auto;
    margin-top: 0.2rem;
    width: 40%;
    height: 8%;
    font-size: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
