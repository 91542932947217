.spin {
  background: var(--img-spin-wrapper);
  @include bgc;
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translateX(-50%);
  width: 50%;
  height: 65%;
  padding: 2.5% 3.6%;

  .spin-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 1.2fr 1fr;
    height: 89%;
    width: 100%;
    gap: 1%;
    background: black;
    padding: 0.5%;

    .spin-box {
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: flex-end;
      color: white;
      position: relative;
      height: 1.04rem;
      padding: 0 5% 3%;

      .glow {
        background: var(--img-box-bg-draw-glow) center/100% 100% no-repeat;
        position: absolute;
        width: 106%;
        height: 106%;
        top: -3%;
        left: -3%;
      }

      &.legendary {
        background: var(--img-box-bg-draw-legendary) center/100% 100% no-repeat;
      }

      &.epic {
        background: var(--img-box-bg-draw-epic) center/100% 100% no-repeat;
      }

      &.rare {
        background: var(--img-box-bg-draw-rare) center/100% 100% no-repeat;
      }

      &.uncommon {
        background: var(--img-box-bg-draw-uncommon) center/100% 100% no-repeat;
      }

      &.common {
        background: var(--img-box-bg-draw-common) center/100% 100% no-repeat;
      }


      //ORDERING
      &-9 {
        grid-column: 1/3;
      }

      &-10 {
        grid-column: 3/5;
      }

      &-9,
      &-10 {
        &.legendary {
          background: var(--img-box-bg-draw-big-legendary) center/100% 100% no-repeat;
        }

        &.epic {
          background: var(--img-box-bg-draw-big-epic) center/100% 100% no-repeat;
        }

        &.rare {
          background: var(--img-box-bg-draw-big-rare) center/100% 100% no-repeat;
        }

        &.uncommon {
          background: var(--img-box-bg-draw-big-uncommon) center/100% 100% no-repeat;
        }

        &.common {
          background: var(--img-box-bg-draw-big-common) center/100% 100% no-repeat;
        }
      }

      .soft-bg {
        z-index: 3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // border-radius: 0.2rem;
        width: 100%;
        height: 100%;
        background: rgba($color: #000, $alpha: 0.6);
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-size: 0.25rem;
          // color: var(--bg-color-get-spin);
          color: white;
        }
      }

      .item-img {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        height: 60%;
        max-width: 100%;
        z-index: 2;
      }

      .item-name {
        font-size: 0.12rem;
        text-align: center;
        text-transform: uppercase;
        @include ellipsis-line(2);
      }
    }
  }

  .spin-btn {
    position: absolute;
    left: 50%;
    bottom: -6%;
    transform: translate(-50%, -50%);
    width: 2.3rem;
    height: 0.7rem;
    background: var(--img-btn-draw-bg) center/100% 100% no-repeat;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.05rem;

    >img {
      height: 45%;
    }

    >span {
      font-size: 0.25rem;
      color: var(--color-btn-active);
      position: relative;
      top: 2%;
    }
  }
}
