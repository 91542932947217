.home {
  width: 100%;
  height: 100%;
  // background: rgba(255,255,255,.2);
  // background: var(--img-main-bg) center/100% 100% no-repeat;
  position: relative;
  padding: 2%;

  &__middle {
    position: absolute;
    top: 2%;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .event-logo {
      width: 47%;
    }

  }
}
