/* SETUP FOR KEEP ASPECT RATIO VIEWPORT */
html {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  @include disableDefaultAction();
}

img {
  pointer-events: none;
}

body {
  width: 100%;
  height: 100%;
}

#root {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 0.36rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#Main {
  position: fixed;
  margin: 0 auto;
  top: 0 !important;
  background: var(--img-main-bg) center/cover no-repeat;
  background-size: cover !important;
  right: 0;
  left: 0;
  // bottom: 0;
  /* setup layout size */
  width: 13.34rem;
  height: 6.6rem;

  &:before {
    content: "";
    position: fixed;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .content-container {
    width: 100%;
    height: 100%;
    position: relative;
    font-family: "GunarBold";
  }
}

/* Modal layout setup */
.react-responsive-modal-root {
  display: flex;
  align-items: center;
  width: 100%;
  height: 6.6rem;
  margin: 0 auto;
}

.react-responsive-modal-overlay {
  overflow: hidden;
}

.react-responsive-modal-container {
  width: 13.34rem !important;
  height: 6.6rem !important;
  display: flex;
  align-items: center;
  margin: 0 auto;
  font-size: 0.36rem;
  position: relative;
}

.react-responsive-modal-modal {
  margin: 0 auto !important;
  max-width: none !important;
  box-shadow: none !important;
  filter: drop-shadow(0 0 0.1rem black);
}

/* END SETUP FOR KEEP ASPECT RATIO VIEWPORT */
