.progress-bar {
  // position: absolute;
  // top: 19%;
  // left: 50%;
  // transform: translateX(-50%);
  width: 4.5rem;
  height: 0.5rem;
  display: flex;

  .left-bar {
    // background-color: white;
    width: 12%;
    height: 100%;
    z-index: 4;

    border-radius: 50%;
    // border: 0.01rem solid var(--border-color-progress);

    position: relative;
    left: 0.08rem;

    display: flex;
    align-items: center;
    justify-content: center;

    &-inner {
      background: var(--img-bullets-left) center/100% 100% no-repeat;
      width: 95%;
      height: 95%;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.15rem;
    }
  }

  .center-bar {
    margin: auto;
    width: 76%;
    height: 15%;
    background: white;
    z-index: 2;
    // border: 0.01rem solid var(--border-color-progress);
    border-left: none;
    border-right: none;

    display: flex;

    .left-team {
      background: var(--img-bar-progress-left) center/100% 100% no-repeat;
      height: 90%;
      position: relative;
      top: 5%;
    }

    .right-team {
      background: var(--img-bar-progress-right) center/100% 100% no-repeat;
      height: 90%;
      position: relative;
      top: 5%;
    }
  }

  .right-bar {
    // background-color: white;
    width: 12%;
    height: 100%;
    z-index: 4;

    border-radius: 50%;
    // border: 0.01rem solid var(--border-color-progress);

    position: relative;
    right: 0.08rem;

    display: flex;
    align-items: center;
    justify-content: center;

    &-inner {
      background: var(--img-bullets-right) center/100% 100% no-repeat;
      width: 95%;
      height: 95%;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.15rem;
    }
  }
}
