.modal {
  background: var(--img-bg-popup-1) center/100% 100% no-repeat !important;
  font-family: "GunarBold";
  padding: 0.5rem 0.3rem 0.3rem 0.3rem !important;

  &__container {
    height: 100%;
    overflow-y: hidden;

    &.congrats {
      height: 95%;
    }

    &.keepyourspirit {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__title {
    text-align: center;

    >div {
      background: -webkit-linear-gradient(var(--color-title-gradient-start),
          var(--color-title-gradient-finish));
      background-clip: text;
      -webkit-text-fill-color: #0000;
      text-transform: uppercase;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.2rem;

    height: 85%;
    font-family: "Gunar";

    &.consent {
      .consent-title {
        font-size: 0.4rem;
        padding: 0.05rem 0;
      }

      .consent-subtitle {
        font-size: 0.3rem;
        padding: 0.05rem 0;
      }

      .consent-content {
        font-size: 0.25rem;
        width: 70%;
        height: 60%;
        margin: auto;
        margin-top: 0.2rem;
        overflow: auto;
        padding-right: 0.3rem;
        color: var(--color-default);
        line-height: 1.25;

        >ol,
        ul {
          list-style: unset !important;
          padding-left: 0.3rem;

          li {
            margin-top: 0.17rem;
          }
        }
      }

      .consent-button-group {
        position: relative;
        top: 4%;
        display: flex;
        justify-content: center;
        font-size: 0.2rem;
        width: 100%;
        height: 12%;
        gap: 0.5rem;

        >div {
          width: 20%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &.message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      >p {
        width: 70%;
        margin: auto;
        color: var(--color-default);
        font-size: 0.25rem;
        text-align: center;
        line-height: 1.25;
      }

      .btn-confirm {
        height: 15%;
        width: 25%;
        font-size: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.chooseside {
      margin-top: 0;
    }

    &.error {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-size: 0.5rem;
        font-family: "GunarBold";
        // margin-bottom: 0.95rem;
        position: absolute;
        top: 15%;
      }

      p {
        font-size: 0.3rem;
        color: var(--color-default);
        text-align: center;
        width: 60%;
        line-height: 1.5;
      }

      .btn-confirm {
        height: 12.7%;
        width: 26%;
        font-size: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 0.7rem;
      }
    }

    &.eventinfo {
      display: flex;
      flex-direction: column;
      height: 100%;

      .info-container {
        display: flex;
        flex-direction: column;
        background: var(--img-bg-popup-1) center/100% 100% no-repeat !important;
        width: 100%;
        height: 88%;
        padding-top: 0.5rem !important;
        padding-bottom: 0.4rem !important;

        .info-tabs {
          display: flex;
          height: 7%;
          width: 45%;
          justify-content: space-evenly;
          margin: auto;
          font-family: "GunarBold";

          .tab {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 50%;
            height: 100%;
            background: var(--img-btn-tab-info-inactive) center/100% 100% no-repeat;
            color: var(--color-btn-info-inactive);
            text-transform: uppercase;
            font-size: 0.17rem;
            cursor: pointer;

            &.active {
              background: var(--img-btn-tab-info-active) center/100% 100% no-repeat;
              color: var(--color-btn-info-active);
            }
          }
        }

        .info-content {
          height: 89%;
          width: 100%;
          padding: 0 10%;

          .rule-content {
            background: var(--img-bg-txt-info) center/100% 100% no-repeat;
            margin: auto;
            width: 90%;
            height: 85%;
            color: white;
            margin-top: 0.4rem;
            font-size: 0.2rem;
            padding: 0.2rem;

            >div {
              overflow-y: auto;
              height: 100%;
              padding-left: 0.2rem;
              line-height: 1.4;
              padding-right: 0.3rem;

              /* width */
              &::-webkit-scrollbar {
                width: .1rem;
                border-radius: 5px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 5px;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background: #888;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: #555;
              }

              >ol {
                list-style: decimal !important;
                padding-left: 0.3rem;

                li {
                  margin-top: 0.07rem;
                }
              }
            }
          }

          .tutorial-content {
            margin-top: 0.4rem;
            padding: 0;

            img {
              background: var(--img-bg-txt-info) center/100% 100% no-repeat;
              padding: 0.05rem;
              width: 80%;
              margin: auto;
            }
          }
        }
      }
    }

    &.keepyourspirit {
      display: flex;
      align-items: center;

      // height: 100%;
      .spirit-title {
        font-size: 0.5rem;
        text-align: center;
        font-family: "GunarBold";
        text-transform: uppercase;
        background: -webkit-linear-gradient(var(--color-title-gradient-start),
            var(--color-title-gradient-finish));
        background-clip: text;
        -webkit-text-fill-color: #0000;
      }

      .spirit-subtitle {
        font-size: 0.2rem;
        color: white;
        text-align: center;
        line-height: 1.25;
        margin-top: 0.1rem;
      }

      .item-group {
        display: flex;
        justify-content: space-evenly;
        gap: 0.4rem;

        .box-item {
          background: var(--img-bg-item) center/100% 100% no-repeat;
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.4rem;

          >img {
            width: 70%;
          }
        }
      }

      .btn-confirm {
        height: 10%;
        width: 29%;
        font-size: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.2rem;
      }
    }

    &.congrats {
      display: flex;
      align-items: center;
      height: 100%;

      .congrats-title {
        font-size: 0.5rem;
        text-align: center;
        font-family: "GunarBold";
      }

      .congrats-subtitle {
        font-size: 0.2rem;
        color: white;
        text-align: center;
        line-height: 1.25;
        margin-top: 0.1rem;
      }

      .box-item {
        background: var(--img-bg-item) center/100% 100% no-repeat;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.4rem;

        >img {
          width: 70%;
        }
      }

      .btn-confirm {
        height: 12.5%;
        width: 25%;
        font-size: 0.24rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.2rem;
      }
    }

    &.confirmation {
      height: 100%;
      width: 60%;
      margin: auto;

      .confirm-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        width: 100%;

        .dontshow {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.15rem;

          .tickbox {
            height: 0.2rem;
            width: 0.2rem;
            border: 1px solid white;
            position: relative;

            &.active {
              background-color: white;

              .tick-ico {
                display: block;
              }
            }

            .tick-ico {
              display: none;
              position: absolute;
              width: 100%;
              left: 0;
              right: 0;
              margin: 0 auto;
              top: 0;
            }
          }

          >span {
            color: var(--color-default);
            font-size: 0.21rem;
            text-align: center;
          }
        }

        p {
          color: white;
          font-size: 0.27rem;
          text-align: center;
          line-height: 1.25;
        }

        .button-section {
          height: 15%;
          width: 80%;

          .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45%;
            height: 100%;
            font-size: 0.2rem;
          }
        }
      }
    }

    &.buildset {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .buildset-header {
        width: 100%;
        height: 7%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn {
          height: 80%;
          width: 14%;
          font-size: 0.12rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        >span {
          font-size: 0.25rem;
          text-align: center;
          position: relative;
          top: 0.07rem;
          font-family: "GunarBold";
        }
      }

      .buildset-content {
        width: 100%;
        height: 85%;

        padding: 0.25rem 0.55rem;
        padding-bottom: 0.05rem;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 0.2rem 0.4rem;

        .buildset-section {
          &.section-1 {
            grid-column: 1/1;
            grid-row: 1/1;
          }

          &.section-2 {
            grid-column: 2/2;
            grid-row: 1/1;
          }

          &.section-3 {
            grid-column: 3/3;
            grid-row: 1/1;
          }

          &.section-4 {
            grid-column: 1/1;
            grid-row: 2/2;
          }

          &.section-5 {
            grid-column: 3/3;
            grid-row: 2/2;
          }

          &.section-6 {
            grid-column: 1/1;
            grid-row: 3/3;
          }

          &.section-7 {
            grid-column: 2/2;
            grid-row: 3/3;
          }

          &.section-8 {
            grid-column: 3/3;
            grid-row: 3/3;
          }

          .section-head {
            margin: auto;
            width: 70%;
            height: 20%;

            background: var(--img-bg-txt-select) center/100% 100% no-repeat;

            color: white;
            text-align: center;
            font-size: 0.17rem;
            // font-family: "GunarMedium";

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            >span {
              position: relative;
              top: 5%;
              color: var(--color-txt-select-draw);
            }
          }

          .section-bottom {
            width: 100%;
            height: 77%;
            margin-top: 3%;
            display: flex;
            align-items: center;
            gap: 4%;

            .box-item {
              background: var(--img-bg-item) center/100% 100% no-repeat;
              width: 39%;
              height: 96%;

              display: flex;
              align-items: center;
              justify-content: center;

              &.active {
                background: var(--img-bg-frame-item) center/100% 100% no-repeat;
              }

              >img {
                width: 80%;
              }
            }

            .arrow {
              width: 6%;

              img {
                width: 100%;
              }
            }
          }
        }
      }

      .btn-draw {
        position: absolute;
        top: 48%;
        font-size: 0.16rem;
        height: 0.4rem;
        width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  //INDIVIDUAL MODAL
  &-keepyourspirit {
    padding-top: 0 !important;
  }

  &-eventinfo {
    background: none !important;
    padding: 0;
  }

  &-congrats {
    padding-top: 0.2rem !important;
    padding-bottom: 0.1rem !important;
  }

  &-chooseside {
    display: flex;
    justify-content: center;
    background: var(--img-bg-popup-2) center/100% 100% no-repeat !important;

    .chooser {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      height: 85%;

      .frame-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        gap: 0.1rem;

        >img {
          height: 10%;
        }

        .frame-choose {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          width: 59.8%;
          height: 80%;

          >img {
            width: 90%;
          }
        }

        .active {
          background: var(--img-frame-choose) center/100% 100% no-repeat !important;
        }

        >p {
          font-size: 0.3rem;
          color: white;
        }
      }
    }

    .choose-count {
      position: absolute;
      text-align: center;
      bottom: 18%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.2rem;
      color: var(--color-txt-select-draw);
      font-style: italic;
      // font-family: "GunarMedium";
    }

    .btn-confirm {
      position: absolute;
      text-align: center;
      bottom: 9%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.2rem;
      width: 20%;
      height: 7%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-buildset {
    padding: 0.1rem 0.2rem 0.2rem 0.3rem !important;
  }
}

.popup-big {
  width: 80%;
  height: 90%;
}

.popup-medium {
  width: 76%;
  height: 68%;
}

.popup-small {
  width: 76%;
  height: 56%;
}

.popup-special {
  width: 76%;
  height: 100%;
}

.react-responsive-modal-closeButton {
  top: 0.5rem !important;
  right: 0.5rem !important;

  >img {
    width: 0.3rem;
  }
}

.slick-prev {
  z-index: 5;
  left: 0 !important;
  background: var(--img-arrow-left) center/100% 100% no-repeat !important;

  &:before {
    content: "" !important;
  }
}

.slick-next {
  right: 0 !important;
  background: var(--img-arrow-right) center/100% 100% no-repeat !important;

  &:before {
    content: "" !important;
  }
}

.slick-dots li button:before {
  color: rgba(255, 255, 255, 0.5) !important;
}

.slick-dots li.slick-active button:before {
  color: white !important;
}
