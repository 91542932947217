.navbar {
  width: 100%;
  height: 17%;
  display: flex;
  justify-content: space-between;

  .event-info {
    height: 32%;
    width: 2.1rem;
    color: white;
    font-size: 0.14rem;
    background-color: rgba(0, 0, 0, 0.4);
    @include flexrc;

    img {
      position: absolute;
      left: 0.3rem;
      height: 0.25rem;
    }

    >span {
      position: absolute;
      top: 0.37rem;
      left: 0.7rem;
      text-transform: uppercase;
    }
  }

  .user-info {
    width: 20%;

    display: flex;
    flex-direction: column;
    color: black;
    align-items: flex-end;
    gap: 0.05rem;

    >span:nth-of-type(1) {
      font-size: 0.25rem;
    }

    >span:nth-of-type(2) {
      display: flex;
      align-items: center;
      font-size: 0.19rem;

      >img {
        margin-left: 0.1rem;
        width: 0.25rem;
        height: 0.25rem;
      }
    }
  }
}
