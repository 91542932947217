body[data-lang=vi],
body[data-lang=vn],
body[data-lang=ru] {

  *,
  *::before,
  *::after {
    font-family: $vnru-font-family;

  }
}

body[data-lang=zh],
body[data-lang=zh-tw] {

  *,
  *::before,
  *::after {
    font-family: $zh-font-family;
  }
}

body[data-lang=ar],
body[data-lang=ur] {
  direction: rtl;
}

body[data-lang=my] {

  *,
  *::before,
  *::after {
    font-family: $my-font-family;
  }
}

body[data-lang=th] {
  @import url("https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&display=swap");

  #Main {
    .content-container {
      font-family: "Mitr", sans-serif;

      .btn-active,
      .btn-inactive {
        font-family: "Mitr", sans-serif;
      }
    }

    .react-responsive-modal-root {
      .react-responsive-modal-container {
        .modal {
          font-family: "Mitr", sans-serif;

          .btn-active,
          .btn-inactive {
            font-family: "Mitr", sans-serif;
          }

          &__content {
            font-family: "Mitr", sans-serif;

            &.error {
              h1 {
                font-family: "Mitr", sans-serif;
              }
            }

            &.eventinfo {
              .info-tabs {
                font-family: "Mitr", sans-serif;
              }
            }

            &.congrats {
              .congrats-title {
                font-family: "Mitr", sans-serif;
              }
            }

            &.buildset {
              .buildset-header {
                >span {
                  font-family: "Mitr", sans-serif;
                }
              }
            }


            &.keepyourspirit {
              .spirit-title {
                font-family: "Mitr", sans-serif;
              }
            }
          }
        }
      }
    }
  }

}
